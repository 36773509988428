<template>
  <article 
    class="ui-nft-card" 
    @click="$emit('click', value)">

    <div 
      class="ui-nft-card__tags" 
      v-if="tags.length > 0">
      <data-tag 
        :appearance="tag.appearance"
        :key="tag.label + i"
        v-for="(tag, i) in tags"
      >{{ tag.label }}</data-tag>
    </div>

    <ui-asset
      class="ui-nft-card__asset"
      :value="asset"
      v-if="asset"
    />

    <div class="ui-nft-card__header">
      <h5 class="ui-nft-card__name">{{ name }}</h5>

      <p 
        class="ui-nft-card__token" 
        v-if="serial"
      >#{{ serial }}</p>
    </div>

    <!-- Footer in case of transfer or actions -->
    <div 
      class="ui-nft-card__footer"
      v-if="transfer">
      <actions-button
        :appearance="$pepper.Appearance.PRIMARY"
        :disabled="disabled"
        :loading="loading"
        class="ui-nft-card__faction"
        @click.stop.prevent="$emit('claim', value)"
      >{{ $t('user-portal.action_claim') }}</actions-button>
    </div>
  </article>
</template>

<script>
import UiAsset from './asset.vue'

export default {
  name: 'UiNftCard',

  components: {
    UiAsset,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    transfer: { 
      type: Boolean,
      default: false,
    },

    value: { 
      type: Object,
      required: true
    }
  },

  computed: {
    asset() {
      return this.$basil.get(this.value, 'assetUrl', null)
    },

    description() {
      return this.$basil.get(this.value, 'description', null)
    },

    name() {
      return this.$basil.get(this.value, 'name', null)
    },

    serial() {
      return this.$basil.get(this.value, 'id', null)
    },

    tags() {
      return [
        // true ? {
        //   label: this.$t('user-portal.nft_peer_transfer_ongoing_tag'),
        //   appearance: this.$pepper.Appearance.PRIMARY,
        // } : null,
      ].filter(t => t != null)
    },
  },
}
</script>