<template>
  <main>
    <div class="container">
      <!-- Header -->
      <header class="user__header">
        <navigations-breadcrumb :children="breadcrumbs" />

        <div class="view-user-wallet__header">
          <h1 class="user__title">{{ $t('user-portal.user_wallet_transfers_title')}}</h1>

          <actions-button
            class="view-user-wallet__aheader"
            icon-pre="redo"
            :disabled="loading"
            :loading="loading"
            @click="onRefresh"
          />
        </div>
      </header>

      <div class="view-user-wallet__section">
        <div 
          class="view-user-wallet__loader" 
          v-if="loading">
          <ui-loader />
        </div>

        <!-- No Transfers -->
        <data-empty
          class="view-user-wallet__empty" 
          :description="$t('user-portal.user_wallet_no_transfers_description')"
          :title="$t('user-portal.user_wallet_no_transfers')"
          :primary-action="$t('user-portal.user_wallet_no_transfers_action')"
          @click="onGoToWallet"
          v-if="!loading && transfers.length === 0"
        />

        <!-- Transfers list -->
        <div 
          class="view-user-wallet__nfts" 
          v-if="!loading && transfers.length > 0">
          <ui-nft-card
            :disabled="claiming != null"
            :key="transfer.id"
            :loading="claiming === transfer.id"
            transfer
            :value="transfer.nft"
            @click="() => onNftClick(transfer)"
            @claim="() => onNftClaim(transfer)"
            v-for="transfer in transfers"
          />
        </div>
      </div>
    </div>

    <router-view 
      :back-route="{ name: 'sayl-user-portal.transfers' }"
      is-transfer
      @refresh="onRefresh"
      v-if="!loading"
    />
  </main>
</template>

<script>
import NavigationsBreadcrumb from '@/components/navigations/breadcrumb'
import UiNftCard from '@/components/ui/nft-card.vue'

export default {
  name: 'ViewClaimNFT',

  components: {
    NavigationsBreadcrumb,
    UiNftCard,
  },

  inject: [
    '$nft',
    '$wallet',
  ],

  data() {
    return {
      breadcrumbs: [
        { label: this.$t('user-portal.user_account_title'), href: { name: 'sayl-user-portal.home' }},
        { label:  this.$t('user-portal.user_wallet_title'), href: { name: 'sayl-user-portal.wallet' }},
        { label:  this.$t('user-portal.user_wallet_transfers_title'), href: '#' },
      ],

      loading: false,
      claiming: null,
      selected: null
    }
  },

  computed: {
    transfers() {
      return this.$wallet.transfers || []
    }
  },

  methods: {
    onGoToWallet() {
      this.$router.push({ name: 'sayl-user-portal.wallet' }).catch(() => {})
    },

    async onNftClaim(transfer) {
      try {
        this.claiming = transfer.id
        await this.$nft.claim({ nft_transfer_id: transfer.id })
        this.claiming = null
        
        this.$notification({
          title: this.$t('user-portal.nft_claimed_success'), 
          message: this.$t('user-portal.nft_claimed_success_message'), 
          type: 'success'
        })

        this.onRefresh()
      } catch(e) {
        this.claiming = null
        this.$notification({
          title: this.$t('user-portal.nft_claimed_error'), 
          message: this.$t('user-portal.nft_claimed_error_message'), 
          type: 'error'
        })

        $console.error(e)
      }
    },

    onNftClick(transfer) {
      this.selected = transfer
      this.$router.push({ 
        name: 'sayl-user-portal.transfers.nft', 
        params: { 
          transfer: this.$basil.get(transfer, 'id'),
          contract: this.$basil.get(transfer, 'nft.contract.address', null),
          id: this.$basil.get(transfer, 'nft.id', null)
        }
      }).catch(() => {})
    },

    async onRefresh() {
      try {
        this.loading = true
        await this.$wallet.getTransfers()
      } catch(e) {
        $console.error(e)
      } finally {
        this.loading = false
      }
    },
  },

  mounted() {
    this.onRefresh()
  }
}
</script>